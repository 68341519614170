/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  /* @apply font-inter; */
}

#root {
  min-height: 100vh;
}

.app-layout-menu {
  margin-top: 1rem;
}

.app-layout-menu .ant-menu-item {
  width: 100%;
  border-radius: 3px;
  height: auto !important;
  margin: 5px 0 10px 0 !important;
  line-height: 1.6 !important;
  padding: 5px 10px !important;
}

#app-layout-header .ant-dropdown {
  /* margin-left: -70px; */
}
